import React, { useRef, useEffect, useState, useCallback } from "react";
import { Calendar } from "react-calendar";
import coverImg from "../../utils/cover-img.jpg";
import Address from "../address/Address";
import "./checkout.css";
import "react-calendar/dist/Calendar.css";
import api from "../../api/api";
//import rozerpay from '../../utils/ic_razorpay.svg';
//import paystack from '../../utils/ic_paystack.svg';
//import Stripe from '../../utils/Checkout_stripe.svg';
import cod from "../../utils/ic_cod.svg";
import { useDispatch, useSelector } from "react-redux";
// import paypal from "../../utils/ic_paypal.svg";
//import Midtrans from "../../utils/Icons/Midtrans.svg";
//import PhonepeSVG from "../../utils/Icons/Phonepe.svg";
import tropipay_svg from "../../utils/ic_tropipay.svg";
import paymentlink from "../../utils/paymentlink.svg";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Modal from "react-bootstrap/Modal";
import { BiLink } from "react-icons/bi";
import {
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

//lottie animation JSONs
import Lottie from "lottie-react";
import animate1 from "../../utils/order_placed_back_animation.json";
import animate2 from "../../utils/order_success_tick_animation.json";

//payment methods
// import useRazorpay from 'react-razorpay';
// import { loadStripe } from '@stripe/stripe-js';
// import {   Elements,} from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm'
// import InjectCheckout from './StripeModal';
// import PaystackPop from '@paystack/inline-js';
import Loader from "../loader/Loader";
import Promo from "../cart/Promo";
import { useTranslation } from "react-i18next";
import {
  setCart,
  setCartCheckout,
  setCartProducts,
  setCartPromo,
  setCartSubTotal,
  setWallet,
} from "../../model/reducer/cartReducer";
// import { AiOutlineCloseCircle } from 'react-icons/ai';
import { PiWallet } from "react-icons/pi";
import { deductUserBalance } from "../../model/reducer/authReducer";
import { ValidateNoInternet } from "../../utils/NoInternetValidator";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import { formatDate } from "../../utils/formatDate";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const city = useSelector((state) => state.city);
  const cart = useSelector((state) => state.cart);
  const address = useSelector((state) => state.address);
  const user = useSelector((state) => state.user);
  const setting = useSelector((state) => state.setting);

  const [paymentUrl, setpaymentUrl] = useState(null);
  const [codAllow, setCodAllow] = useState(0);
  const [totalPayment, setTotalPayment] = useState(null);
  const [walletDeductionAmt, setWalletDeductionAmt] = useState(null);
  // const [order, setOrder] = useState(false);
  const [walletAmount, setWalletAmount] = useState(user?.user?.balance);
  // const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [IsOrderPlaced, setIsOrderPlaced] = useState(false);
  const [expectedDate, setexpectedDate] = useState(null);
  const [timeslots, settimeslots] = useState(null);
  const [selectedAddress, setselectedAddress] = useState(null);
  // const today = new Date();
  const [expectedTime, setexpectedTime] = useState();
  const [paymentMethod, setpaymentMethod] = useState("");
  // const [deliveryTime, setDeliveryTime] = useState("");
  // const [orderID, setOrderID] = useState(0);
  const [loadingPlaceOrder, setloadingPlaceOrder] = useState(false);
  // const [stripeOrderId, setstripeOrderId] = useState(null);
  // const [stripeClientSecret, setstripeClientSecret] = useState(null);
  // const [stripeTransactionId, setstripeTransactionId] = useState(null);
  const [show, setShow] = useState(false);
  const [showPromoOffcanvas, setShowPromoOffcanvas] = useState(false);
  // const [stripeModalShow, setStripeModalShow] = useState(false);
  const [isFullWalletPay, setIsFullWalletPay] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const paypalStatus = useRef(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [orderNote, setOrderNote] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [total_delivery_charge, setTotal_delivery_charge] = useState(0);

  // const stripePromise = loadStripe(setting?.payment_setting && setting?.payment_setting?.stripe_publishable_key);
  const shareDiv = document.getElementById("share");

  const [isChecked, setIsChecked] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  const [isPickUp, setPickup] = useState(0);

  /** La recogida en almacen esta cableada, para arrancar con el negocio hay una variable pickup en la tabla city y luego otra variable pick_up
   * en la tabla ordenes. que me permite control de forma basica si se puedo o no recoger pero sujeto solo a una unica ubicacion por ciudad
   */
  // useEffect(() => {
  //   if (city.pickup === 1) {
  //     setIsChecked(true);
  //     setPickup(0);
  //   }
  //   if (city.pickup === 0) {
  //     setIsChecked(false);
  //     setPickup(0);
  //   }
  //   if (city.pickup === -1) {
  //     setIsVisible(false);
  //     setPickup(0);
  //   }
  // }, [city.pickup]);

  const hideShowBotonesdeCompartir = (display) => {
    if(document.getElementById("share")){
      const shareDiv = document.getElementById("share");
      shareDiv.style.display = display;
    }
    
  };



  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    setPickup(checked === true ? 1 : 0);
    setTotalPayment(
      checked === true
        ? totalPayment - cart.checkout.delivery_charge.total_delivery_charge
        : totalPayment + cart.checkout.delivery_charge.total_delivery_charge
    );
    setTotal_delivery_charge(
      checked === true ? 0 : cart.checkout.delivery_charge.total_delivery_charge
    );
    if (paymentMethod === "COD") {
      setpaymentMethod("Tropipay");
      document.getElementById("radio_tropipay").checked = true;
      document.getElementById("radio_cod").checked = false;
    }
  };

  // console.log("Payment Methods ->", setting?.payment_setting, expectedTime);
  useEffect(() => {
    api
      .getCart(user?.jwtToken, city.city.latitude, city.city.longitude, 1)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCartCheckout({ data: result.data }));
          dispatch(setWallet({ data: 0 }));
          if (cart?.promo_code) {
            setTotalPayment(
              result.data.total_amount - cart?.promo_code?.discount
            );
          } else {
            setTotalPayment(result.data.total_amount);            
          }
          setTotal_delivery_charge(cart.checkout.delivery_charge.total_delivery_charge);
          setWalletAmount(user?.user?.balance);
        } else if (result.status === 0) {
          dispatch(setCartCheckout({ data: null }));
          toast.error(t("no_items_found_in_cart"));
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
        const isNoInternet = ValidateNoInternet(error);
        if (isNoInternet) {
          setIsNetworkError(isNoInternet);
        }
      });

    api
      .getCartSeller(user?.jwtToken, city.city.latitude, city.city.longitude, 1)
      .then((res) => res.json())
      .then((result) => {
        setCodAllow(result?.data?.cod_allowed);
        setpaymentMethod("Tropipay");
        hideShowBotonesdeCompartir("none");
      })
      .catch((error) => console.log(error));
    fetchTimeSlot();
  }, []);

  useEffect(() => {
    if (
      address?.selected_address?.latitude &&
      address?.selected_address?.longitude
    )
      api
        .getCart(
          user?.jwtToken,
          address?.selected_address?.latitude,
          address?.selected_address?.longitude,
          1
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            setCodAllow(result?.data?.cod_allowed);
            dispatch(setCartCheckout({ data: result.data }));
            dispatch(setWallet({ data: 0 }));
            if (cart?.promo_code) {
              setTotalPayment(
                result.data.total_amount - cart?.promo_code?.discount);
            } else {
              setTotalPayment(result.data.total_amount);              
            }
            setTotal_delivery_charge(cart.checkout.delivery_charge.total_delivery_charge);
          }
        })
        .catch((error) => console.log(error));
  }, [address?.selected_address]);

  const checkLastOrderTime = (lastTime) => {
    const currentTime = expectedDate ? expectedDate : new Date();
    if (currentTime > new Date()) {
      return true;
    }
    const hours = lastTime.split(":")[0];
    const minutes = lastTime.split(":")[1];
    const seconds = lastTime.split(":")[2];

    const lastOrderTime = new Date();

    lastOrderTime.setHours(hours);
    lastOrderTime.setMinutes(minutes);
    lastOrderTime.setSeconds(seconds);

    return currentTime <= lastOrderTime;
  };

  const fetchTimeSlot = () => {
    api
      .fetchTimeSlot()
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          if (result?.data?.time_slots_is_enabled == "false") {
            // toast.error(t("timeslots_not_enabled"));
          }
          settimeslots(result.data);
          setexpectedTime(
            result?.data?.time_slots.filter((element) =>
              checkLastOrderTime(element?.last_order_time)
            )[0]
          );
        }
      })
      .catch((error) => console.log(error));
  };

  // Filter the time slots based on last_order_time
  useEffect(() => {
    const currentDateTime = new Date();
    setexpectedDate(
      new Date(
        currentDateTime.setDate(
          currentDateTime.getDate() +
            (Number(timeslots?.time_slots_delivery_starts_from) - 1)
        )
      )
    );
  }, [timeslots]);

  useEffect(() => {
    setexpectedTime(
      timeslots?.time_slots.filter((element) =>
        checkLastOrderTime(element?.last_order_time)
      )[0]
    );
  }, [expectedDate]);

  useEffect(() => {
    if (cart?.is_wallet_checked && totalPayment > walletAmount) {
      setWalletDeductionAmt(walletAmount);
      setWalletAmount(0);
      setTotalPayment(totalPayment - walletAmount);
      setIsFullWalletPay(false);
    } else if (cart?.is_wallet_checked && totalPayment <= walletAmount) {
      const remainingwalletBalance = walletAmount - totalPayment;
      setWalletDeductionAmt(totalPayment);
      setWalletAmount(remainingwalletBalance);
      setTotalPayment(0);
      setIsFullWalletPay(true);
      setpaymentMethod("Wallet");
    } else if (!cart?.is_wallet_checked && cart?.promo_code) {
      setTotalPayment(
        cart?.checkout?.total_amount - cart?.promo_code?.discount
      );
      setWalletAmount(user.user.balance);
      setWalletDeductionAmt(0);
      setIsFullWalletPay(false);
    } else {
      setTotalPayment(cart?.checkout?.total_amount);
      setWalletAmount(user.user.balance);
      setWalletDeductionAmt(0);
      setIsFullWalletPay(false);
    }
  }, [cart?.is_wallet_checked]);

  const HandlePlaceOrder = async (e) => {
    const delivery_time = `${expectedDate.getDate()}-${expectedDate.getMonth() + 1}-${expectedDate.getFullYear()} ${expectedTime?.title}`;
    setloadingPlaceOrder(true);

    // Validación de tiempo de entrega
    if (!delivery_time) {
        toast.error(t("prefered_delivery_time"));
        setloadingPlaceOrder(false);
        return false;
    }

    // Configuración de los datos comunes para la orden
    const orderData = {
        jwtToken: user?.jwtToken,
        product_variant_id: cart.checkout.product_variant_id,
        quantity: cart.checkout.quantity,
        sub_total: cart.checkout.sub_total,
        delivery_charge: total_delivery_charge,
        totalPayment,
        paymentMethod,
        address_id: address.selected_address.id,
        delivery_time,
        promocode_id: cart.promo_code?.promo_code_id,
        walletDeductionAmt: cart.is_wallet_checked ? walletDeductionAmt : null,
        walletUsed: cart.is_wallet_checked ? 1 : 0,
        orderNote,
        isPickUp
    };

    // Manejo del método de pago
    switch (paymentMethod) {
        case "COD":
            await placeOrder(orderData, true);
            break;
        case "Wallet":
            await placeOrder(orderData);
            break;
        case "Tropipay":
            await placeOrderAndInitiateTransaction(orderData, "Tropipay");
            break;
        case "ENLDP":
            await placeOrderAndInitiateTransaction(orderData, "ENLDP");
            break;
        default:
            toast.error("Método de pago no soportado.");
            setloadingPlaceOrder(false);
            break;
    }
};

// Función para realizar la orden y manejar la respuesta
const placeOrder = async (orderData, isCOD = false) => {
    try {
        const response = await api.placeOrder(
            orderData.jwtToken,
            orderData.product_variant_id,
            orderData.quantity,
            orderData.sub_total,
            orderData.delivery_charge,
            orderData.totalPayment,
            orderData.paymentMethod,
            orderData.address_id,
            orderData.delivery_time,
            orderData.promocode_id,
            orderData.walletDeductionAmt,
            orderData.walletUsed,
            orderData.orderNote,
            orderData.isPickUp
        );

        const result = await response.json();
        setisLoader(false);
        if (result.status === 1) {
            handleSuccessOrder(isCOD, result.data.order_id);
        } else {
            toast.error(result.message);
        }
    } catch (error) {
        handleError(error);
    } finally {
        setloadingPlaceOrder(false);
    }
};

// Función para realizar la orden y luego iniciar la transacción
const placeOrderAndInitiateTransaction = async (orderData, paymentType) => {
    try {
      if(orderData.totalPayment>2000){
        toast.error("El monto total a pagar no debe exceder los 2000 EUR");
        return;
      }
      if(orderData.totalPayment<5){
        toast.error("La compra mínima es de 5 EUR");
        return;
      }
        const response = await api.placeOrder(
            orderData.jwtToken,
            orderData.product_variant_id,
            orderData.quantity,
            orderData.sub_total,
            orderData.delivery_charge,
            orderData.totalPayment,
            orderData.paymentMethod,
            orderData.address_id,
            orderData.delivery_time,
            orderData.promocode_id,
            orderData.walletDeductionAmt,
            orderData.walletUsed,
            orderData.orderNote,
            orderData.isPickUp
        );

        const result = await response.json();
        if (result.status === 1) {
            await initiateTransaction(result.data.order_id, paymentType);
        } else {
            toast.error(result.message);
        }
    } catch (error) {
        handleError(error);
    } finally {
        setloadingPlaceOrder(false);
    }
};

// Función para iniciar una transacción
const initiateTransaction = async (order_id, paymentType) => {
    try {
        const response = await api.initiate_transaction(user?.jwtToken, order_id, paymentType, "order");
        const res = await response.json();

        if (res.status === 1) {
            setpaymentUrl(res.data.redirectUrl);
            setIsButtonVisible(true);
            api.removeCart(user?.jwtToken);
            dispatch(setCart({ data: null }));
            dispatch(setCartPromo({ data: null }));
            dispatch(setCartProducts({ data: [] }));
            dispatch(setCartSubTotal({ data: 0 }));
            if(paymentMethod === "ENLDP"){              
              toast.info("Su enlace de pago ha sido generado. Compártalo con la persona que completará el pago");
              hideShowBotonesdeCompartir("block");
              setIsButtonVisible(false);
              return;
            }
            window.open(res.data?.redirectUrl, "_parent");
           
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        handleError(error);
    }
};

// Función para manejar éxito de la orden
const handleSuccessOrder = (isCOD, order_id) => {
    setOrderNote("");
    // toast.success(t("order_success_placed"));
    dispatch(setWallet({ data: 0 }));
    dispatch(setCartPromo({ data: null }));
    dispatch(setCartProducts({ data: [] }));
    dispatch(setCartSubTotal({ data: 0 }));
    dispatch(deductUserBalance({ data: walletDeductionAmt }));
    setIsOrderPlaced(true);
    setShow(true);
    

    if (isCOD) {
        // Lógica adicional para pagos COD si es necesario
    }
};

// Función para manejar errores del servidor
const handleError = (error) => {
    console.error(error);
    toast.error("Ha ocurrido un error. Por favor, intenta de nuevo.");
};

  const handleClose = () => {
    setisLoader(true);
    api
      .removeCart(user?.jwtToken)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          dispatch(setCart({ data: null }));
          dispatch(setCartCheckout({ data: null }));
        }
      });
    setShow(false);
    paypalStatus.current = false;
    navigate("/");
  };

  useEffect(() => {
    if (IsOrderPlaced) {
      setShow(true);
      setTimeout(async () => {
        handleClose();
      }, 5000);
    }
  }, [IsOrderPlaced]);

  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDisabledClick = () => {
    setIsDisabled(true);
  };
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };
  const getEstimatedDeliveryDate = () => {
    const daysToAdd =
      parseInt(setting?.setting?.web_settings?.delivery_estimate_days) || 0;

    // Create a new date object for the current date
    const currentDate = new Date();

    // Add the specified number of days
    const deliveryDate = new Date(
      currentDate.setDate(currentDate.getDate() + daysToAdd)
    );
    return formatDate(deliveryDate);
  };
  const current = new Date();
  return (
    <>
      {!isNetworkError ? (
        <>
          <section id="checkout">
            {IsOrderPlaced ? (
              <>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={true}
                  className="success_modal"
                  dialogClassName="successModalDialog"
                >
                  <Lottie
                    className="lottie-content"
                    animationData={animate1}
                    loop={true}
                  ></Lottie>
                  <Modal.Header
                    closeButton
                    className="flex-column-reverse success_header"
                  >
                    <Modal.Title>
                      <Lottie
                        animationData={animate2}
                        loop={false}
                        className="lottie-tick"
                      ></Lottie>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="success_body d-flex flex-column justify-content-center align-items-center">
                    <div>{t("order_placed_description")}</div>
                    <button onClick={handleClose} className="checkout_btn">
                      {t("go_to_home")}
                    </button>
                  </Modal.Body>
                  {/* <Modal.Footer className="success_footer">

                            </Modal.Footer> */}
                </Modal>
              </>
            ) : null}

            <div className="cover">
              <img
                src={coverImg}
                onError={placeHolderImage}
                className="img-fluid"
                alt="cover"
              ></img>
              <div className="title">
                <h3>{t("checkout")}</h3>
                <span>
                  <Link to="/" className="text-white text-decoration-none">
                    {t("home")} /{" "}
                  </Link>{" "}
                </span>
                <span className="active">{t("checkout")}</span>
              </div>
            </div>

            {!setting.payment_setting === null && !expectedTime === null ? (
              <Loader screen="full" />
            ) : (
              <>
                <div className="checkout-container container">
                  <fieldset
                    disabled={isDisabled}
                    className="checkout-util-container col-lg-9"
                  >
                    <div className="checkout-util-container col-lg-9">
                      <div className="billibg-address-wrapper checkout-component">
                        <span className="heading">
                          {t("Detalles de para las entrega")}
                        </span>
                        <Address
                          setselectedAddress={setselectedAddress}
                          selectedAddress={selectedAddress}
                        />
                      </div>
                      {timeslots &&
                      timeslots.time_slots_is_enabled === "true" ? (
                        <>
                          <div className="delivery-day-wrapper checkout-component">
                            <span className="heading">{t("prefered_day")}</span>
                            <div className="d-flex justify-content-center p-3 calendarContainer">
                              <Calendar
                                value={
                                  expectedDate.toString() === "Invalid Date"
                                    ? new Date(
                                        current.setDate(
                                          current.getDate() +
                                            (Number(
                                              timeslots?.time_slots_delivery_starts_from
                                            ) -
                                              1)
                                        )
                                      )
                                    : expectedDate
                                }
                                onChange={(e) => {
                                  if (new Date(e) >= new Date()) {
                                    setexpectedDate(new Date(e));
                                  } else if (
                                    new Date(e).getDate() ===
                                      new Date().getDate() &&
                                    new Date(e).getMonth() ===
                                      new Date().getMonth() &&
                                    new Date(e).getFullYear() ===
                                      new Date().getFullYear()
                                  ) {
                                    setexpectedDate(new Date(e));
                                  } else {
                                    toast.info(
                                      t("please_select_valid_delivery_day")
                                    );
                                  }
                                }}
                                calendarType={"gregory"}
                                className={"checkoutCalendar"}
                                minDate={
                                  new Date(
                                    current.setDate(
                                      current.getDate() +
                                        (Number(
                                          timeslots?.time_slots_delivery_starts_from
                                        ) -
                                          1)
                                    )
                                  )
                                }
                                maxDate={
                                  new Date(
                                    current.setDate(
                                      current.getDate() +
                                        Number(
                                          timeslots?.time_slots_allowed_days - 1
                                        )
                                    )
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="delivery-time-wrapper checkout-component">
                            <span className="heading">
                              {t("prefered_time")}
                            </span>
                            <div
                              className="d-flex p-3"
                              style={{ flexWrap: "wrap" }}
                            >
                              {timeslots === null ? (
                                <Loader screen="full" />
                              ) : (
                                <>
                                  {timeslots?.time_slots
                                    .filter((element) =>
                                      checkLastOrderTime(
                                        element?.last_order_time
                                      )
                                    )
                                    .map((timeslot, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="time-slot-container"
                                        >
                                          <div>
                                            <input
                                              type="radio"
                                              name="TimeSlotRadio"
                                              id={`TimeSlotRadioId${index}`}
                                              defaultChecked={
                                                index === 0 ? true : false
                                              }
                                              onChange={() => {
                                                setexpectedTime(timeslot);
                                              }}
                                            />
                                          </div>
                                          <div>{timeslot?.title}</div>
                                        </div>
                                      );
                                    })}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="delivery-time-wrapper checkout-component">
                            <span className="heading">
                              {t("estimate_delivery_date")}
                            </span>
                            <div className="d-flex justify-content-start align-items-center estimateDeliveryDate">
                              <span>
                                {t("estimate_delivery_date")} :{" "}
                                {getEstimatedDeliveryDate()}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </fieldset>
                  <div className="order-container">
                    {user?.user?.balance > 0 ? (
                      <div className="promo-section">
                        <div className="heading">
                          <span>{t("Wallet")}</span>
                        </div>
                        <div className="promo-wrapper">
                          <div className="promo-container">
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                className="image-container d-flex align-items-center"
                                style={{ gap: "15px" }}
                              >
                                <PiWallet
                                  size={35}
                                  fill={"var(--secondary-color)"}
                                />
                                <span style={{ fontSize: "14px" }}>
                                  {t("Wallet Balance")}
                                </span>
                                <p
                                  style={{
                                    color: "var(--secondary-color",
                                    fontSize: "14px",
                                  }}
                                  className="mb-0 me-2"
                                >
                                  {/* {t(parseFloat(user?.user?.balance).toFixed(setting?.setting && setting?.setting?.decimal_point))} */}
                                  {t(
                                    parseFloat(walletAmount).toFixed(
                                      setting?.setting &&
                                        setting?.setting?.decimal_point
                                    )
                                  )}
                                  &nbsp;
                                  {setting.setting && setting.setting.currency}
                                </p>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  disabled={IsOrderPlaced ? true : false}
                                  checked={
                                    cart.is_wallet_checked ? true : false
                                  }
                                  onClick={() => {
                                    cart.is_wallet_checked
                                      ? dispatch(setWallet({ data: 0 }))
                                      : dispatch(setWallet({ data: 1 }));
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {isFullWalletPay ? (
                      <></>
                    ) : (
                      <fieldset
                        disabled={isDisabled}
                        className="payment-wrapper checkout-component"
                      >
                        <div className="payment-wrapper checkout-component">
                          <span className="heading">{t("payment_method")}</span>
                          {setting?.payment_setting?.cod_payment_method ===
                            "1" && codAllow === 1 ? (
                            <label
                              className="form-check-label cursorPointer"
                              htmlFor="cod"
                            >
                              <div className="payment-selector">
                                <img src={cod} alt="cod" />
                                <div className="">
                                  <span>{t("cash_on_delivery")}</span>
                                </div>
                                <input
                                  type="radio"
                                  name="payment-method"
                                  id="radio_cod"
                                  onChange={() => {
                                    setpaymentMethod("COD");
                                    hideShowBotonesdeCompartir("none");
                                    if (!isChecked) {
                                      setIsChecked(true);
                                      setPickup(1);
                                      setTotalPayment(
                                        totalPayment -
                                          cart.checkout.delivery_charge
                                            .total_delivery_charge
                                      );
                                      setTotal_delivery_charge(0);
                                      toast.info(
                                        t(
                                          "Tenga en cuenta que solo es posible pagar en efectivo si recoge en nuestro almacen"
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </label>
                          ) : null}
                          {setting?.payment_setting.tropipay_payment_method ===
                          "1" ? (
                            <label
                              className="form-check-label cursorPointer"
                              htmlFor="tropipay"
                            >
                              <div className="payment-selector">
                                <img src={tropipay_svg} alt="tropipay" />
                                <div className="">
                                  <span>Visa / Mastercard</span>
                                </div>
                                <input
                                  type="radio"
                                  name="payment-method"
                                  id="radio_tropipay"
                                  defaultChecked={true}
                                  onChange={() => {
                                    setpaymentMethod("Tropipay");
                                    hideShowBotonesdeCompartir("none");
                                  }}
                                />
                              </div>
                            </label>
                          ) : null}
                          {setting?.payment_setting.tropipay_payment_method ===
                          "1" ? (
                            <label
                              className="form-check-label cursorPointer"
                              htmlFor="ENLDP"
                            >
                              <div className="payment-selector">
                                <img src={paymentlink} alt="Enlace de pago" />
                                <div className="">
                                  <span> {t("payment_link")}</span>
                                </div>
                                <input
                                  type="radio"
                                  name="payment-method"
                                  id="ENLDP"
                                  onChange={() => {
                                    setpaymentMethod("ENLDP");
                                    hideShowBotonesdeCompartir("block");
                                  }}
                                />
                              </div>
                            </label>
                          ) : null}

                          {setting?.payment_setting?.stripe_payment_method ===
                          "1" ? (
                            <label
                              className="form-check-label cursorPointer"
                              htmlFor="stripe"
                            >
                              <div className="payment-selector">
                                {/* <img src={Stripe} alt='stripe' /> */}
                                <div className="">
                                  <span className="ps-2"> {t("stripe")}</span>
                                </div>
                                <input
                                  type="radio"
                                  name="payment-method"
                                  id="stripe"
                                  onChange={() => {
                                    setpaymentMethod("Stripe");
                                  }}
                                />
                              </div>
                            </label>
                          ) : null}

                          {setting?.payment_setting?.paypal_payment_method ===
                          "1" ? (
                            <>
                              <label
                                className="form-check-label cursorPointer"
                                htmlFor="paypal"
                              >
                                <div className="payment-selector">
                                  {/* <img src={paypal} alt='paypal' /> */}
                                  <div className="">
                                    <span>{t("paypal")}</span>
                                  </div>
                                  <input
                                    type="radio"
                                    name="payment-method"
                                    id="paypal"
                                    onChange={() => {
                                      setpaymentMethod("Paypal");
                                    }}
                                  />
                                </div>
                              </label>
                            </>
                          ) : null}
                        </div>
                      </fieldset>
                    )}
                    <fieldset
                      disabled={isDisabled}
                      className="checkout-component order-instructions-wrapper"
                    >
                      <div className="checkout-component order-instructions-wrapper">
                        <div className="heading">{t("Lugar para recogidas")}</div>
                        <div className="order-instruction-body">
                          <ul>
                            <p style={{ fontSize: "16px" }}>
                              Recogida en almacen &nbsp;
                              <input
                                style={{ width: "22px", height: "22px" }}
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            </p>
                            <br></br>
                            <p style={{ fontSize: "16px" }}>
                              <strong>La direccion es:</strong> Calle 13 e/26 y 28 Vedado, La
                              Habana. Almacen Danaus Srl
                            </p>
                            <p style={{ fontSize: "16px" }}>
                              El horario de recogida es de 8:30am a las 4pm
                            </p>
                          </ul>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset
                      disabled={isDisabled}
                      className="checkout-component order-instructions-wrapper"
                    >
                      <div className="checkout-component order-instructions-wrapper">
                        <div className="heading">{t("order_note")}</div>
                        <div className="order-instruction-body">
                          <textarea
                            rows={4}
                            cols={5}
                            className="order-instructions-input"
                            placeholder={`${t(
                              "Escriba una nota si necesita especificar algo más para este pedido"
                            )}`}
                            onChange={(e) => setOrderNote(e.target.value)}
                            maxLength={191}
                          />
                        </div>
                      </div>
                    </fieldset>

                    <div className="order-summary-wrapper checkout-component">
                      <div className="order-bill">
                        <div className="heading">{t("order_summary")}</div>
                        <div className="order-details">
                          {cart.checkout === null || user.user === null ? (
                            <Loader screen="full" />
                          ) : (
                            <div className="summary">
                              <div className="d-flex justify-content-between">
                                <span>{t("sub_total")}</span>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {cart?.checkout?.sub_total?.toFixed(
                                      setting.setting &&
                                        setting.setting.decimal_point
                                    )}
                                    &nbsp;
                                    {setting.setting &&
                                      setting.setting.currency}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between">
                                <span>{t("delivery_charge")}</span>
                                <div className="d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {total_delivery_charge?.toFixed(
                                      setting.setting &&
                                        setting.setting.decimal_point
                                    )}{" "}
                                    &nbsp;
                                    {setting.setting &&
                                      setting.setting.currency}
                                  </span>
                                </div>
                              </div>
                              {cart.promo_code && (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <span>{t("discount")}</span>
                                    <div className="d-flex align-items-center">
                                      <span>
                                        -{" "}
                                        {Number(
                                          cart?.promo_code?.discount
                                        )?.toFixed(
                                          setting.setting &&
                                            setting.setting.decimal_point
                                        )}
                                        &nbsp;
                                        {setting.setting &&
                                          setting.setting.currency}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {walletDeductionAmt ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <span>{t("Wallet")}</span>
                                    <div className="d-flex align-items-center">
                                      <span>
                                        -{" "}
                                        {Number(walletDeductionAmt)?.toFixed(
                                          setting.setting &&
                                            setting.setting.decimal_point
                                        )}
                                        &nbsp;
                                        {setting.setting &&
                                          setting.setting.currency}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="d-flex justify-content-between total">
                                <span>{t("total")}</span>
                                <div
                                  className="d-flex align-items-center total-amount"
                                  style={{ color: "var(--secondary-color)" }}
                                >
                                  <span>
                                    {Number(totalPayment)?.toFixed(
                                      setting.setting &&
                                        setting.setting.decimal_point
                                    )}
                                    &nbsp;
                                    {setting.setting &&
                                      setting.setting.currency}
                                  </span>
                                </div>
                              </div>

                              {loadingPlaceOrder ? (
                                <Loader
                                  screen="full"
                                  background="none"
                                  content={
                                    "Your transaction is being processed.Please don't refresh the page."
                                  }
                                />
                              ) : (
                                <>
                                  {(setting.payment_setting
                                    .cod_payment_method === "1" &&
                                    codAllow === "1") ||
                                  setting.payment_setting
                                    .razorpay_payment_method === "1" ||
                                  setting.payment_setting
                                    .paystack_payment_method === "1" ||
                                  setting.payment_setting
                                    .stripe_payment_method === "1" ||
                                  setting.payment_setting
                                    .tropipay_payment_method === "1" ||
                                  setting.payment_setting
                                    .paypal_payment_method === "1" ||
                                  setting?.payment_setting
                                    ?.phonepay_payment_method === "1" ||
                                  setting?.payment_setting
                                    ?.midtrans_payment_method === "1" ? (
                                    <div className="button-container">
                                     {isButtonVisible ? (
  <motion.button whiletap={{ scale: 0.8 }} type='button' id="btn_payment" className='checkout'
    onClick={async (e) => { 
      e.preventDefault();    
      if (!expectedDate) {
        toast.error(t('please_select_date'));
        return;
      }
      if (!address.address) {
        toast.error(t("please_select_address"));
        return;
      }
      if (address.selected_address === null) {
        toast.error(t("please_select_default_address"));
        return;
      }     
      if (paymentMethod === "") {
        toast.error(t("please_select_payment_method"));
        setloadingPlaceOrder(false);
        return;
      }
      // var products=checkStockProductos();
      // if ( products=== "") {
      //   toast.error(t("Hay productos agotados en su carrito: "+products));
      //   setloadingPlaceOrder(false);
      //   return;
      // }
      await HandlePlaceOrder();
      handleDisabledClick();
      hideShowBotonesdeCompartir("block");
    }}>
    {paymentMethod === "ENLDP" ? t('generate_payment_link') : t("place_order")}
  </motion.button>
) : (
  paymentMethod === "ENLDP" && (
    <div className='share' id="share" style={{ display: 'block' }}>
      <span style={{ fontSize: "16px" }}>{t("share_payment_link")} :</span>
      <ul className='share-product'>
        <li className='share-product-icon'>
          <WhatsappShareButton url={`${paymentUrl}`} onClick={(e) => {
            if (paymentUrl === null) {
              e.preventDefault();
              toast.error(t("Primero debe generar el enlace de pago"));
            }
          }}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
        </li>
        
        <li className='share-product-icon' onClick={(e) => {
          if (paymentUrl === null) {
            e.preventDefault();
            toast.error(t("Primero debe generar el enlace de pago"));
          }
        }}>
          <TelegramShareButton url={`${paymentUrl}`}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
        </li>
        
        <li className='share-product-icon' style={{ width: "240px" }}>
          <button type="button" onClick={() => {
            if (paymentUrl === null) {
              toast.error(t("Primero debe generar el enlace de pago"));
            } else {
              navigator.clipboard.writeText(`${paymentUrl}`);
              toast.success(t("copied_successfull"));
            }
          }}>
            <BiLink size={30} />Copiar enlace
          </button>
        </li>
      </ul>
    </div>
  )
)}

                                    </div>
                                  ) : (
                                    <div className="button-container">
                                      <button
                                        type="button"
                                        className="checkout"
                                        disabled
                                      >
                                        {t("enable_payment_methods")}
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <Promo show={showPromoOffcanvas} setShow={setShowPromoOffcanvas} />
          </section>
          {/* <Modal id="stripeModal" size='lg' centered show={stripeModalShow}>
                        <Modal.Header onClick={() => setStripeModalShow(false)

                        } className='header justify-content-between'>
                            <span style={{ color: '#33a36b', fontSize: '18px', fontWeight: 'bolder' }}>{process.env.REACT_APP_WEB_NAME} Payment</span>
                            <span style={{ cursor: 'pointer' }}>
                                <AiOutlineCloseCircle size={20} />
                            </span>

                        </Modal.Header>
                        <Modal.Body>

                            {stripeOrderId === null || stripeClientSecret === null || stripeTransactionId === null
                                ? <Loader width='100%' height='100%' />
                                :
                                <Elements stripe={stripePromise} orderID={stripeOrderId} client_secret={stripeClientSecret} transaction_id={stripeTransactionId} amount={totalPayment}>
                                    <InjectCheckout setIsOrderPlaced={setIsOrderPlaced} setShow={setStripeModalShow} orderID={stripeOrderId} client_secret={stripeClientSecret} transaction_id={stripeTransactionId} amount={totalPayment}
                                        setWalletAmount={setWalletAmount} walletAmount={user?.user?.balance}
                                        walletDeductionAmt={walletDeductionAmt}
                                    />
                                </Elements>
                            }

                        </Modal.Body>
                    </Modal> */}
        </>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center noInternetContainer">
          <MdSignalWifiConnectedNoInternet0 />
          <p>{t("no_internet_connection")}</p>
        </div>
      )}
    </>
  );
};

export default Checkout;
