import React, { useEffect, useState } from "react";
import { IoChevronUp } from "react-icons/io5";
import { useSelector } from "react-redux";
import {
    WhatsappIcon,
    WhatsappShareButton,
  } from "react-share";

  
const ScrollTop = () => {
    const [isVisible, setIsvisible] = useState(false);
    

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsvisible(true);
        } else {
            setIsvisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const setting = useSelector((state)=> state.setting)
    const defaultNumber = "5358472911";
  const phoneNumber = setting.setting ? setting.setting.support_number  : defaultNumber;
  const message = "Hi, Hola, tengo una inquietud acerca de la tienda online";
    

    return (
        
        // <div className="scroll-to-top">
        //     {/* <a href={`${"http://api.whatsapp.com/send?phone="+setting.setting.support_number }`} > */}
        //   <a className="back-top-container" href={"http://api.whatsapp.com/send?phone=17868226199&text=Hola, tengo una inquietud"} 
        //   target="_blank" rel="noopener noreferrer">
        //   <WhatsappIcon size={50}   round={false}  borderRadius={7}/>          
        //   </a>  
          
        //     {isVisible && (
        //         <div onClick={scrollToTop} className="back-top-container" style={{ marginTop: "10px" }} > 
        //             <IoChevronUp size={26} />
        //         </div>
        //     )}
            
        // </div>
<div className="scroll-to-top">
      <a
        className="back-top-container"
        href={`http://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsappIcon size={50} round={false} borderRadius={7} />
      </a>

      {isVisible && (
        <div onClick={scrollToTop} className="back-top-container" style={{ marginTop: "10px" }}>
          <IoChevronUp size={26} />
        </div>
      )}
    </div>

    );
};
export default ScrollTop;