import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './model/store';
import { Provider } from 'react-redux';
import { Persiststore } from './model/store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./utils/i18n.js";
import { Helmet } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Helmet>
          <meta name="title" content="Okisss: Tu Tienda Online en Cuba para Productos de Calidad" />
          {/* <meta name="description" content={setting?.setting?.web_settings?.common_meta_description} /> */}
          <title>Okisss: Tu Tienda Online en Cuba para Productos de Calidad</title>
                {/* <link rel="icon" href="/logo.png"></link> */}
                <meta name="description" content="Descubre en Okisss la variedad más amplia de productos en Cuba. Tecnología, moda, hogar y más con envíos rápidos y seguros. ¡Compra ya y vive la experiencia Okisss!" />
                <meta name="keywords" content="envíos a Cuba, tienda online Cuba, alimentos Cuba, supermercado Cuba, bebidas Cuba, 
                productos de aseo Cuba, electrodomésticos Cuba, compras online Cuba, envíos rápidos a Cuba, comida Cuba, productos frescos Cuba,
                 compra desde el exterior Cuba, tienda en línea Cuba, productos de limpieza Cuba, compras seguras Cuba, envíos económicos a Cuba, 
                 apoyo familiar Cuba, compras familiares Cuba, alimentos esenciales Cuba, bebidas y alimentos Cuba, productos de aseo personal Cuba, 
                 tecnología Cuba, compras de electrodomésticos Cuba, mercado online Cuba, envíos confiables a Cuba, productos de calidad Cuba, 
                 comprar para Cuba, supermercado online Cuba, envío de comida Cuba, compras desde el exterior para Cuba, tienda de alimentos Cuba, 
                 productos básicos Cuba, envío de productos Cuba, comida y bebida Cuba, compras para familiares en Cuba, alimentos frescos Cuba, 
                 tecnología y electrodomésticos Cuba, tienda online confiable Cuba, productos esenciales Cuba, supermercado virtual Cuba, 
                 compras rápidas Cuba, compras online seguras Cuba, tienda de confianza Cuba, mercado virtual Cuba, plataforma de compras Cuba,
                 electrodomésticos para Cuba, productos aseo personal Cuba, alimentos para Cuba, tienda y envíos Cuba." />
                <meta name="robots" content="index,follow" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://okisss.com/" />
                <meta property="og:title" content="Okisss: Tu Tienda Online en Cuba para Productos de Calidad" />
                <meta property="og:description" content="Encuentra todo lo que necesitas con facilidad y comodidad en Okisss. Navega por nuestras categorías y descubre las mejores ofertas en una amplia gama de productos. ¡Tu satisfacción es nuestra prioridad!" />
                <meta property="og:image" content="/logo.png"/> 
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://okisss.com/" />
                <meta name="twitter:title" content="Okisss: Tu Tienda Online en Cuba para Productos de Calidad" />
                <meta name="twitter:description" content="Descubre en Okisss la variedad más amplia de productos en Cuba. Tecnología, moda, hogar y más con envíos rápidos y seguros. ¡Compra ya y vive la experiencia Okisss!" />
                <meta name="twitter:image" content="/logo.png" />
        </Helmet>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persiststore}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      // console.log(error);
    });
  });
}
const getAllCookies = () => {
  const cookies = document.cookie.split('; ');
  const cookieObject = {};
  // console.info("todas la cokkies:" +cookies);
  cookies.forEach(cookie => {
    const [name, value] = cookie.split('=');
    cookieObject[name] = value;
  });
 
  return cookieObject;
};
const setEvaluateTimeCookie = () => {
  const creationTime = new Date().toISOString();
  document.cookie = "evaluatetime="+creationTime+";path=/"  ;
};

const deleteAllCookies = () => {
  const cookies = document.cookie.split('; ');

  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  });
  document.cookie = 'egrocery-1d4fe.firebaseapp.com=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  document.cookie = 'egrocery-1d4fe.firebaseapp.com=; Max-Age=-99999999;'; 
  document.cookie = "egrocery-1d4fe.firebaseapp.com=;expires=" + new Date(0).toUTCString()

};
const checkEvaluateTimeCookie = () => {
  const cookies = getAllCookies();
  const evaluateTimeCookie = cookies['evaluatetime'];

  if (evaluateTimeCookie) {
    const creationTime = new Date(evaluateTimeCookie).getTime();
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - creationTime;

    // 24 horas en milisegundos
    const twentyFourHours = 24 * 60 * 60 * 1000;
    // const hoursElapsed = timeElapsed / (1000 * 60 * 60);
    if (timeElapsed >= twentyFourHours) {
    //if (hoursElapsed >= 0.05) {
      deleteAllCookies();
      setEvaluateTimeCookie();
      // console.log('Todas las cookies han sido eliminadas y la cookie evaluatetime ha sido actualizada.');
    } else {
      
      // const hoursRemaining = 24 - hoursElapsed;
      // console.log("La cookie evaluatetime lleva creada: "+ hoursElapsed.toFixed(2) +"horas.");
      // console.log("Faltan "+hoursRemaining.toFixed(2)+"  horas para que la cookie alcance las 24 horas: ");
    }
  } else {
    setEvaluateTimeCookie();
    // console.log('La cookie evaluatetime ha sido creada.');
  }
};

// Llama a la función para verificar y gestionar la cookie 'evaluatetime' cada vez que se accede al sitio web
checkEvaluateTimeCookie();

